<template>
  <div class="main">
    <div class="chat_body">
      <div class="chat-left">
        <div class="top_add_box">
          <el-button type="primary" @click="createNewChat"><i class="el-icon-plus add_i"></i>新建对话</el-button>
        </div>
        <!-- 会话列表 -->
        <div ref="scrollContainer" class="chat_list" @scroll="handleScroll">
          <div v-for="(chat,chat_index) in chatLists" :key="'chat'+chat_index" class="chat_item" :class="{'active_chat':current_chat_id==chat.id}">
            <p v-if="!chat.show_edit" class="chat_namt" :class="{'chat_namt_red':current_chat_id==chat.id}" @click="selectChat(chat)">{{ chat.name }}</p>
            <div v-else class="input_box">
              <!-- @blur="chat.show_edit = false" -->
              <el-input ref="chat_input" v-model="chat.new_name" placeholder="请输入内容"></el-input>
              <i class="el-icon-close close_btn" @click="chat.show_edit = false"></i>
              <i class="el-icon-check done_btn" @click="updateChatName(chat)"></i>
            </div>
            <span class="ctr_span" :class="{'ctr_span_block':current_chat_id==chat.id}">
              <el-popover
                ref="popoverRef"
                placement="right"
                width="100"
                trigger="click"
                popper-class="menu_pop"
                :close-delay="0"
              >
                <div class="pop_menus">
                  <p class="p1" @click="editChatitem(chat,chat_index)"><i class="el-icon-edit"></i>重命名</p>
                  <p class="p2" @click="deleteOneChat(chat,chat_index)"><i class="el-icon-delete"></i>删除对话</p>
                </div>
                <el-button slot="reference" type="text"><i class="el-icon-more"></i></el-button>
              </el-popover>
            </span>
          </div>
        </div>
      </div>
      <div v-loading="aloading" class="chat-card">
        <el-scrollbar>
          <!-- 历史记录 -->
          <div v-if="historyList&&historyList.length>0" ref="historyScrollContainer" class="chat-content">
            <div v-for="(item,index) in historyList" :key="index" class="content-main">
              <div class="picture">
                <img :src="item.type == 1 ? 'https://newoss.zhulong.com/forum/202206/02/46/103546ckyxy5lcwwkes8du.png':avator" alt="" onerror="javascript:this.src='https://newoss.zhulong.com/tfs/noavatar_big.gif'">
                <p v-if="item.type == 1">小鱼儿</p>
              </div>
              <div class="content-body">
                <div>
                  <div v-if="item.type==1" class="message incoming">
                    <div class="setImg" v-html="item.content"></div>
                    <!-- 分割线 -->
                    <div v-if="index!=list.length-1||!btn_disabled" class="copy">
                    </div>
                  </div>
                  <div v-if="item.type==2" class="message outgoing">
                    <p>{{ item.content }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 提示话术 -->
          <div class="chat-top">
            <div class="picture">
              <img src="https://newoss.zhulong.com/forum/202206/02/46/103546ckyxy5lcwwkes8du.png" alt="">
              <p>小鱼儿</p>
            </div>
            <div class="chat-body">
              <div class="name">Hello，我是小鱼儿，你的建筑知识助手 😊</div>
              <div class="dec">作为筑龙<span>AI建筑知识问答助手</span>，</div>
              <!-- <div class="dec">我能为你提供建筑知识相关问题的解决方案，和<span>设计规范</span>中的相关回答。</div> -->
              <div class="dec">我是基于建筑行业标准规范和确定知识回答您的问题，我不会杜撰也不会回答无关话题。</div>
              <div class="dec" style="margin: 20px 0 0;">你可以试着问我：</div>
              <div class="questionlist">
                <div v-for="(item,index) in questionlist" :key="index" @click="!btn_disabled&&send(item.content)">{{ item.content }}</div>
              </div>
            </div>
          </div>
          <!-- 聊天记录 -->
          <div class="chat-content">
            <div v-for="(item,index) in list" :key="index" class="content-main">
              <div class="picture">
                <img :src="item.type == 1 ? 'https://newoss.zhulong.com/forum/202206/02/46/103546ckyxy5lcwwkes8du.png':avator" alt="" onerror="javascript:this.src='https://newoss.zhulong.com/tfs/noavatar_big.gif'">
                <p v-if="item.type == 1">小鱼儿</p>
              </div>
              <div class="content-body">
                <div>
                  <div v-if="item.type==1" class="message incoming">
                    <div v-if="index==list.length-1&&loading">
                      <section class="dots-container">
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                      </section>
                    </div>
                    <div v-else>
                      <div style="color: #333;" class="setImg" v-html="item.content"></div>
                      <!-- 缓冲规范链接用 -->
                      <!-- <span v-if="index==list.length-1&&gf_url_text_state==1">
                        <section class="dots-container">
                          <div class="dot"></div>
                          <div class="dot"></div>
                          <div class="dot"></div>
                          <div class="dot"></div>
                          <div class="dot"></div>
                        </section>
                      </span> -->
                      <!-- 分割线 -->
                      <div v-if="index!=list.length-1||!btn_disabled" class="copy">
                      </div>
                    </div>
                  </div>
                  <div v-if="item.type==2" class="message outgoing">
                    <p>{{ item.content }}</p>
                  </div>
                </div>
                <!-- 踩 -->
                <!-- <div v-if="item.type == 1 && (showUnlike && unlike_item_index == index)" class="unlike_box">
                  <div class="tags_box">
                    <span v-for="(tag,tidx) in unlikeTags" :key="tidx" class="tag_item" :class="{'active_tag':unlike_tag_id==tag.title}" @click="selectUnlikeTag(tag)">{{ tag.title }}</span>
                  </div>
                  <div class="input_box">
                    <el-input v-model="unlike_reason" class="reason_ipt" placeholder="（可选）希望您能写下对此答案感到不满意的原因，也可以写下您的建议来帮助我们改进，谢谢！"></el-input>
                    <span class="submit_btn" @click="submitUnlike">提交</span>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </el-scrollbar>
        <!-- 聊天框 -->
        <div class="chat-bottom">
          <div class="boxhidden"></div>
          <div class="question-body">
            <el-input
              v-model="message"
              :rows="3"
              type="textarea"
              placeholder="请输入您的问题"
            >
            </el-input>
            <div v-if="!btn_disabled" class="send" @click="sendBtnFunc()">
              <img src="https://newoss.zhulong.com/forum/202404/16/14/1713249195295295.png" alt="">
            </div>
            <div v-else class="stop_send" @click="stopChunk()">
              <img src="https://newoss.zhulong.com/forum/202404/22/16/1713774304531809.png" alt="">
            </div>
          </div>
        </div>

      </div>

    </div>
    <!-- 弹窗 -->
    <el-dialog
      title="规范来源详情"
      class="my_dialog"
      :visible.sync="guifanDialog"
      width="700px"
      @closed="closedGfDialog"
    >
      <div class="dialog_box">
        <div class="gf_content setImg" v-html="guifanContent"></div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import cookies from 'js-cookie'
import MarkdownIt from 'markdown-it'
import Clipboard from 'clipboard'
import hljs from 'highlight.js'
import { getConversations, getCurrentMessages } from '@/api/chatGPT'
import { fetchEventSource } from '@microsoft/fetch-event-source'
import axios from 'axios'

export default {
  data() {
    return {
      // 登录头像
      avator: '',
      // 登录uid
      logUid: '',
      loading: false, // 加载动画，对话开始的加载动画
      aloading: false, // 加载动画，页面加载动画
      btn_disabled: false, // 控制按钮是否可以点击
      // 输入框的输入内容，提问的内容
      message: '',
      // copy_text: '',
      questionlist: [
        {
          'id': 1,
          'content': '大体积混凝土的验收规范是什么？'
        },
        {
          'id': 2,
          'content': '混凝土搅拌桩的施工工艺是什么？'
        },
        {
          'id': 3,
          'content': '在民用建筑设计统一标准中，是如何规定建筑分类的？'
        },
        {
          'id': 4,
          'content': '楼梯扶手的高度应该是多少？'
        }
      ],
      // 会话列表
      chatListQuery: {
        user: '',
        // 当前页最后面一条记录的 ID，默认 null
        last_id: null,
        limit: 30,
        // 可选值：created_at, -created_at, updated_at, -updated_at：字段前面的符号代表顺序或倒序，-代表倒序
        sort_by: '-updated_at'
      },
      chatLists: [],
      // 当前会话id
      current_chat_id: null,
      // 当前会话下历史记录的query
      historyQuery: {
        conversation_id: '',
        user: '',
        first_id: '',
        limit: 20
      },
      // 会话列表弹出窗
      showMenuPop: false,
      // 历史记录
      historyList: [],
      // 历史记录的监听
      resizeObserver: null,
      // 当前会话的新聊天列表数据
      list: [],
      // 获取传递过来的问题
      keywords: this.$route.query.words || '',
      // 规范来源弹窗
      guifanDialog: false,
      // 规范文本
      guifanContent: '',
      // 新的解析器
      abortController: null,
      // 当前会话任务id
      chunk_task_id: '',
      // 匹配链接
      gf_url_text_temp: '',
      gf_url_text_state: 0
    }
  },
  computed: {

  },
  created() {
    // 处理登录
    this.checkLogin()
  },
  mounted() {
    // 提问框自适应位置
    this.adaptiveBox()
    window.addEventListener('resize', this.adaptiveBox)
    // 监听滚动
    window.addEventListener('scroll', this.handleScroll)
    // 获取会话列表
    this.getHistoryChatList()
    // 模拟新开会话
    this.initRightPageValues()

    // 如果是切换过会话的
    if (this.$route.query.chat_id) {
      this.selectChatIdNextStep(this.$route.query.chat_id)
    }

    // 获取参数，是否传参过来
    if (this.keywords && this.keywords.length > 0) {
      this.$nextTick(() => {
        this.controlQuerySearch(this.keywords)
      })
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adaptiveBox)
    window.removeEventListener('scroll', this.handleScroll) // 清理事件监听器防止内存泄漏
    this.removeHistoryListListener()
    this.stopChunk()
  },
  methods: {
    // 如果是其他页面传递过来的
    controlQuerySearch(keywords) {
      this.createNewChat()
      this.$nextTick(() => {
        this.send(keywords)
        this.keywords = ''
      })
    },
    // 获取会话列表
    getHistoryChatList() {
      this.chatListQuery.user = this.logUid
      getConversations(this.chatListQuery).then(res => {
        const data = res.data || []
        data.forEach((e) => {
          e.show_edit = false
          e.new_name = e.name
        })
        this.chatLists = data
      })
    },
    // 开启新的对话
    createNewChat() {
      // 判断暂停会话
      this.stopChunk()
      // 开启新的对话：1、替换路由中的chat_id，2、清空list，3、清空历史 4、清空message
      this.$router.replace({ path: this.$router.path, query: { }})
      this.initRightPageValues()
    },
    // 选择一个对话开始聊天
    selectChat(val) {
      console.log(val.id)
      if (val.id == this.current_chat_id) {
        console.log('已是当前会话')
        return
      }
      // 判断暂停会话
      this.stopChunk()
      this.$router.replace({ path: this.$router.path, query: { chat_id: val.id }})
      this.initRightPageValues()
      this.selectChatIdNextStep(val.id)
    },
    // 切换会话、新建会话，右侧页面初始化数据
    initRightPageValues() {
      this.loading = false
      this.aloading = false
      this.current_chat_id = null
      this.historyList = []
      this.list = []
      this.btn_disabled = false
      this.message = ''
      this.historyQuery = {
        conversation_id: '',
        user: '',
        first_id: '',
        limit: 20
      }
      this.removeHistoryListListener()
    },
    // 选择会话，处理之后的操作
    selectChatIdNextStep(idstr, get_history = true) {
      // 加载当前会话的历史记录
      this.current_chat_id = idstr
      this.historyQuery.conversation_id = idstr
      this.historyQuery.user = this.logUid
      if (get_history) {
        // 请求当前会话下的历史记录
        this.getHistoryMsg()
      }
    },
    // 开启新对话，聊天时检测新对话，获取聊天会话，同时也需要替换当前路由
    newChatReplace(idstr, user_query) {
      console.log('替换当前路由', user_query)
      this.$router.replace({ path: this.$router.path, query: { chat_id: idstr }})
      this.selectChatIdNextStep(idstr, false)
      this.is_new_chat = false

      // 需要在请求到新的会话列表之后，根据当前的 idstr 和 user_query对会话重命名
      // 故新的对话 在此请求，并在请求会话列表之前调一次重命名
      var caht_name = user_query.length > 20 ? user_query.slice(0, 20) : user_query
      this.renameRequest(caht_name, idstr)
    },
    // 页面滚动
    handleScroll() {
      this.hideAllPopover()
    },
    // 加载当前会话的历史记录
    getHistoryMsg() {
      getCurrentMessages(this.historyQuery).then(res => {
        const data = res.data || []
        data.forEach(ele => {
          this.historyList.push(
            {
              type: 2,
              content: ele.query,
              initial_content: ''
            },
            {
              type: 1,
              content: this.renderMarkdown((ele.answer || '')), // 渲染历史记录的回答
              initial_content: ''
            }
          )
        })
      }).catch((err) => {
        // 当前会话参数不对，默认切换到新会话
        console.log('会话历史记发生错误', err)
        this.createNewChat()
      }).then(() => {
        this.addCustomLinkListeners()
        this.scorellToBottom()
        // 添加历史记录列表的监听
        if (!this.resizeObserver) {
          console.log('添加监听~~')
          this.addHistoryListListener()
        }
      })
    },
    // 针对一个会话，点击更多事件
    controlOneChat(val) {
      console.log(val, 'more action')
      this.showMenuPop = true
    },
    // 编辑一条会话的名字、
    editChatitem(chat, index) {
      chat.show_edit = true
      this.hideAllPopover()
      this.$nextTick(() => {
        if (this.$refs.chat_input && this.$refs.chat_input.length > 0) {
          this.$refs.chat_input[0].focus()
        }
      })
    },
    // 重命名会话名称
    updateChatName(val) {
      console.log(val)
      // 请求
      this.renameRequest(val.new_name, val.id, val)
    },
    // 重命名请求 chat_obj 为空，是新对话的请请求
    renameRequest(name, chat_id, chat_obj) {
      const url = `${window.location.origin}/ucenter/prod-api/api/aiv3/Rename/conversation_id/${chat_id}`
      const postData = {
        name: name,
        user: this.logUid
      }
      var _this = this
      axios.post(url, postData).then((res) => {
        console.log(res)
        const data = res.data || {}
        const name = data.name || ''
        _this.renameSuccess(name, chat_obj)
      })
    },
    // 重命名成功 val 为空，是新对话的请请求
    renameSuccess(name, val) {
      if (val) {
        val.name = name
        val.new_name = name
        val.show_edit = false
        this.$message.success('修改成功')
      } else {
        // 新会话 重命名成功之后，请求列表
        this.getHistoryChatList()
      }
    },
    // 删除会话
    deleteOneChat(val, index) {
      console.log(val)
      this.hideAllPopover()
      const url = `${window.location.origin}/ucenter/prod-api/api/aiv3/deleteConversation/conversation_id/${val.id}`
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      const postData = {
        conversation_id: val.id,
        user: this.logUid
      }
      axios.post(url, postData, config).then((res) => {
        console.log(res)
        if (res.status == 200) {
          this.$message.success('删除成功')
          this.getHistoryChatList()
          if (this.current_chat_id === val.id) {
            // 模拟开启新会话
            this.createNewChat()
          }
        }
      })
    },
    // 关闭所有popover
    hideAllPopover() {
      const popRefs = this.$refs.popoverRef
      if (popRefs && popRefs.length > 0) {
        popRefs.forEach(pop => {
          pop.doClose()
        })
      }
    },
    // 停止会话
    stopChunk() {
      this.stopCurrentListen()
    },
    // 发送按钮的发送事件
    sendBtnFunc() {
      this.send()
    },
    // 发送文本
    send(val) {
      if (val) {
        this.message = val
      }
      console.log(this.message, 'this.message')
      if (this.message) {
        this.list.push({
          'content': this.message,
          'type': 2
        })
        var message = this.message
        this.message = ''
        this.btn_disabled = true
        setTimeout(() => {
          var obj = {
            'content': '', // md转之后的内容
            'initial_content': '', // 接口返回的初始化内容
            'type': 1
          }
          this.list.push(obj)
          this.loading = true
          this.chat2(message)
          this.scorellToBottom()
        }, 500)
      } else {
        this.$message({
          message: '请输入内容',
          type: 'warning'
        })
      }
    },
    // 发送信息
    chat2(message) {
      const url = `${window.location.origin}/ucenter/prod-api/api/aiv3/chatMessages`
      const uid = cookies.get('uid') || ''
      // 请求的数据
      const postData = {
        'inputs': {},
        'query': message,
        'response_mode': 'streaming',
        'conversation_id': this.current_chat_id,
        'user': uid,
        'files': '',
        'auto_generate_name': 1// true // 是否自动生成会话标题
      }
      console.log(postData, 'postData')
      // 置空当前会话任务id
      this.chunk_task_id = ''
      // 开始
      this.startListening(url, postData)
    },
    // 停止当前会话
    stopCurrentListen() {
      if (this.chunk_task_id && this.chunk_task_id.length > 0) {
        // 需要暂停当前会话
        const url = `${window.location.origin}/ucenter/prod-api/api/aiv3/chatMessagesStop/task_id/${this.chunk_task_id}/stop`
        const postData = {
          user: this.logUid
        }
        axios.post(url, postData).then((res) => {})
        this.chunk_task_id = ''
      }
    },
    // 新的请求方式
    async startListening(url, body) {
      try {
        var _this = this
        let buffer = ''// 数据流
        this.abortController = new AbortController() // 创建一个新的 AbortController
        await fetchEventSource(url, {
          method: 'POST',
          body: JSON.stringify(body),
          signal: this.abortController.signal, // 将 AbortController 的 signal 绑定到请求上
          onopen(response) {
            // 链接会话
            if (response.ok && response.status === 200) {
              // 成功链接
              console.log('Connection established')
              _this.checkIsNewChat(body)
            } else {
              // 链接失败：
              console.error('Failed to connect:', response)
            }
          },
          onmessage(event) {
            // 接收会话流
            //  event: message_end ：消息结束事件，收到此事件则代表流式返回结束。
            // event: message LLM 返回文本块事件，即：完整的文本以分块的方式输出。
            console.log('[SSE RAW]', event)
            // 第1步：判断data是否存在
            const data = (event.data || '')
            if (data && data.length > 0) {
              // 追加数据流
              buffer += data
              console.log(buffer, 'buffer')
              // 第2步：检测是否以 字符串 “data: ”开头
              const pres = 'data: '
              if (buffer.startsWith(pres)) {
                // 第3步：截取字符串，并解析json数据
                var sub_msg = buffer.substring(pres.length, buffer.length)
                try {
                  // 以data:开头截取：
                  const jsonData = JSON.parse(sub_msg)
                  console.log(jsonData, 'jsonData')
                  const json_event = jsonData.event || ''
                  const json_answer = jsonData.answer || ''
                  const task_id = jsonData.task_id || ''

                  // 会话id
                  const conversation_id = jsonData.conversation_id || ''
                  if (conversation_id && conversation_id.length > 0) {
                    console.log('conversation_id = ', conversation_id)
                    // 需要检测是否是新的会话
                    if (_this.is_new_chat) {
                      _this.newChatReplace(conversation_id, body.query)
                    }
                  }

                  // 任务id
                  if (task_id && task_id.length > 0) {
                    console.log('task_id = ', task_id)
                    _this.getChunkTaskId(task_id)
                  }

                  // const message_id = jsonData.message_id || ''
                  // console.log('message_id = ', message_id)

                  // const metadata = jsonData.metadata || {}
                  // if (metadata) {
                  //   console.log(metadata, 'metadata')
                  // }

                  // 会话文本
                  if (json_answer && json_answer.length > 0) {
                    // 有文本打印出来
                    console.log('解析文本：', json_answer)
                    _this.chatMessageStart(json_answer)
                  }

                  // message_end
                  if (json_event === 'message_end') {
                    // 文本解析完成
                    console.log('解析文本 完成')
                    // this.chatMessageEnd()
                  }
                  // 清空缓冲区
                  buffer = ''
                } catch (e) {
                  // json转换报错 ,说明数据可能不完整，继续等待更多数据
                  console.log('sub_msg json转换报错', e, buffer)
                }
              } else {
                // 不以data:开头：
                // 清空缓冲区
                buffer = ''
                console.log('不以data:开头：', buffer)
              }
            } else {
              // event.data 不存在
            }
          },
          onclose() {
            // 正常的结束，本次会话结束
            console.log('Connection closed by the server')
            _this.chatMessageEnd('查询超时，请稍后再试!')
            _this.stopListening()
          },
          onerror(error) {
            console.error('There was an error:', error)
            _this.chatMessageEnd('服务器异常，请稍后再试!')
            _this.stopListening()
            // 禁止重新发起请求
            throw new Error()
          },
          retryDelay: () => Infinity // 设置为无穷大以停止重试
        })
      } catch (error) {
        console.error('Failed to fetch event source:', error)
      }
    },
    // 当前会话流拿到task_id
    getChunkTaskId(task_id) {
      if (task_id && task_id.length > 0) {
        this.chunk_task_id = task_id
      }
    },
    // 检测是否是新对话
    checkIsNewChat(post) {
      const conversation_id = post.conversation_id || ''
      console.log(conversation_id, 'conversation_id')
      if (!(conversation_id && conversation_id.length > 0)) {
        // 新对话 获取会话列表
        console.log('新会话~~~~')
        this.is_new_chat = true
      } else {
        console.log('不是 新会话~~~~')
        this.is_new_chat = false
      }
    },

    // 报错等结束请求
    stopListening() {
      if (this.abortController) {
        console.log('停止监听 ==========', this.abortController)
        this.abortController.abort() // 调用 abort 方法来取消请求
      }
      this.chunk_task_id = ''
    },
    // 开始解析、获取获取数据
    chatMessageStart(answer) {
      console.log('answer = ', answer)
      if (answer.includes('(')) {
        this.gf_url_text_temp = ''
        this.gf_url_text_state = 1
      }
      if (answer.includes(')')) {
        answer = this.gf_url_text_temp + answer
        this.gf_url_text_state = 0
      }
      if (this.gf_url_text_state != 1) {
        this.gf_url_text_temp = ''
        this.loading = false
        var all_content = this.list[this.list.length - 1].initial_content
        all_content += answer
        this.list[this.list.length - 1].initial_content = all_content
        this.list[this.list.length - 1].content = this.renderMarkdown(all_content)
        this.scorellToBottom()
        this.addCustomLinkListeners()
      } else {
        this.gf_url_text_temp += answer
      }
    },
    // 会话数据获取完毕
    chatMessageEnd(error_text = '') {
      if (this.list.length == 0) {
        // 其他操作：切换会话等，如果暂停会话，此处可能 list 被清空了，做个处理
        return
      }
      if (!this.list[this.list.length - 1].content) {
        this.list[this.list.length - 1].content = error_text
      }
      this.loading = false
      this.btn_disabled = false
      this.locsourceLoading = false
      this.scorellToBottom()
    },
    // 移除历史记录的列表监听
    removeHistoryListListener() {
      const scrollContainer = this.$refs.historyScrollContainer
      if (this.resizeObserver) {
        if (scrollContainer) {
          this.resizeObserver.unobserve(scrollContainer)
        }
        this.resizeObserver.disconnect()
        this.resizeObserver = null
      }
    },
    // 添加历史记录列表的监听
    addHistoryListListener() {
      const scrollContainer = this.$refs.historyScrollContainer
      // 创建 ResizeObserver 实例
      var _this = this
      this.resizeObserver = new ResizeObserver(entries => {
        for (const entry of entries) {
          if (entry.contentRect.height > 0) {
            // 当内容高度发生变化时，滚动到底部
            _this.scorellToBottom()
          }
        }
      })
      // 开始观察 scrollContainer 的大小变化
      this.resizeObserver.observe(scrollContainer)
    },

    // markdown渲染，highlight.js高亮
    renderMarkdown(text, href_class = 'gh_href') {
      // gh_href：自定义的a标签，方便绑定点击事件
      // gh_hide_href：隐藏a标签，不显示
      const md = new MarkdownIt({
        highlight: function(str, lang) {
          if (lang && hljs.getLanguage(lang)) {
            try {
              return `<pre class="hljs"><code>${hljs.highlight(str, { language: lang }).value}</code></pre>`
            } catch (__) {}
          }
          return `<pre class="hljs"><code>${md.utils.escapeHtml(str)}</code></pre>`
        }
      })
      md.renderer.rules.link_open = (tokens, idx, options, env, self) => {
        const hrefIndex = tokens[idx].attrIndex('href')
        const href = tokens[idx].attrs[hrefIndex][1]
        // 返回自定义的 a 标签，方便绑定点击事件
        return `<a class='${href_class}' href="${href}">`
      }
      return md.render(text)
    },
    // 给查看详情添加自定义事件
    addCustomLinkListeners() {
      // 使用 $refs 获取渲染后的容器元素
      this.$nextTick(() => {
        var container = document.getElementsByClassName('gh_href')
        if (container) {
          Array.from(container).forEach((item) => {
            item.removeEventListener('click', this.customLinkClick)
            item.addEventListener('click', this.customLinkClick)
          })
        }
      })
    },
    // 自定义的点击事件
    customLinkClick(event) {
      // 阻止默认行为
      event.preventDefault()
      const origin_url = event.target.href || ''
      const url = this.safeDecodeURIComponent(origin_url)
      console.log(url, 'urlurlurlurl')
      const sid = this.getQueryString(url, 'sid') || ''
      const dataset_id = this.getQueryString(url, 'did') || ''
      var document_id = this.getQueryString(url, 'document_id') || ''
      if (!(document_id && document_id.length > 0)) {
        document_id = this.getQueryString(url, 'docid') || ''
      }
      console.log('sid = ', sid)
      console.log('dataset_id = ', dataset_id)
      console.log('document_id = ', document_id)
      this.lookGfInfo(dataset_id, document_id, sid)
    },
    // 安全解码
    safeDecodeURIComponent(encodedURI) {
      try {
        // 尝试解码
        return decodeURIComponent(encodedURI)
      } catch (e) {
        if (e instanceof URIError) {
          console.warn(`无效的 URI 编码: ${encodedURI}`)
          // 可以选择返回原始字符串或其他默认值
          return encodedURI
        } else {
          // 如果是其他类型的错误，重新抛出
          throw e
        }
      }
    },
    // 查看规范新版
    lookGfInfo(dataset_id, document_id, sid) {
      const url = `${window.location.origin}/ucenter/prod-api/api/aiv3/datasets/dataset_id/${dataset_id}/document_id/${document_id}/sid/${sid}`
      var _this = this
      axios.get(url, {}).then((res) => {
        if (res.status == 200) {
          const data = res.data || {}
          const result = data.result || ''
          const content = result.content || ''
          if (content && content.length > 0) {
            _this.getStandardInfoSuccess(content)
          } else {
            _this.getStandardInfoError('没有查找到对应的规范!')
          }
        } else {
          _this.getStandardInfoError('规范原文获取失败!')
        }
      }).catch(() => {
        this.getStandardInfoError('规范原文获取失败~')
      })
    },
    // 获取规范成功
    getStandardInfoSuccess(content) {
      // 渲染规范原文
      this.guifanContent = this.renderMarkdown(content)
      this.guifanDialog = true
    },
    // 获取规范失败
    getStandardInfoError(error) {
      this.$message.error(error)
    },
    // 关闭规范弹窗
    closedGfDialog() {
      this.guifanContent = ''
    },
    // 将聊天窗口滚动到最底部
    scorellToBottom() {
      var element = document.getElementsByClassName('el-scrollbar__wrap')[0] // 获取页面中所有类名为 'chat-content' 的元素的第一个元素
      element.scrollTop = element.scrollHeight // 将该元素的滚动位置设置为其内容的高度，实现滚动到最底部
      // var chatBody = document.getElementsByClassName('chat-body')[0];
      // if (chatBody.scrollTop + chatBody.clientHeight >= chatBody.scrollHeight) {
      //   // 当滚动条位于底部时，执行滚动到底部的操作
      //   chatBody.scrollTop = chatBody.scrollHeight;
      // }
    },
    // 提问框自适应位置
    adaptiveBox() {
      var windowHeight = window.innerHeight || document.documentElement.clientHeight
      console.log(windowHeight, '--------')
      var left = document.getElementsByClassName('chat-left')[0]
      var a = document.getElementsByClassName('chat-card')[0]
      var b = document.getElementsByClassName('el-scrollbar')[0]
      // 265：底部footer高度，顶部panding高度，130：底部输入框高度
      left.style = 'height:' + (windowHeight - 265) + 'px'
      a.style = 'height:' + (windowHeight - 265) + 'px'
      b.style = 'height:' + (windowHeight - 265 - 130) + 'px'
    },
    // 判断是否登录
    checkLogin() {
      var uid = cookies.get('uid')
      var url = window.location.origin + '/ucenter/login?redirect=' + location.href
      if (!uid) {
        window.open(url, '_self')
        return
      }
      // 登录
      this.logUid = uid
      this.getAvator(cookies.get('uid'))
    },
    // 获取地址栏参数
    getQueryString(url, param) {
      // param为要获取的参数名 注:获取不到是为null
      var arr = url.split('?') // 分割域名和参数界限
      if (arr.length > 1) {
        arr = arr[1].split('&') // 分割参数
        for (var i = 0; i < arr.length; i++) {
          var tem = arr[i].split('=') // 分割参数名和参数内容
          if (tem[0] === param) {
            return tem[1]
          }
        }
        return null
      } else {
        return null
      }
    },
    // 生成头像
    getAvator(uid) {
      const domain = 'https://newoss.zhulong.com/tfs/'
      let num = uid
      const n = 9
      let len = num.toString().length
      while (len < n) {
        num = '0' + num
        len++
      }
      uid = num
      const dir1 = uid.substr(0, 3)
      const dir2 = uid.substr(3, 2)
      const dir3 = uid.substr(5, 2)
      const time = parseInt(new Date().getTime() / 1000) + ''
      const ava = domain + dir1 + '/' + dir2 + '/' + dir3 + '/' + uid.substr(-2) + '_avatar_big.jpg?t=' + time
      this.avator = ava
    }
  }
}
</script>
<style lang="scss" scoped>
// ::-webkit-scrollbar {
//   width: 0px !important;
// }
// ::-webkit-scrollbar {
//   width: 0px !important;
//   height: 0;
// }
*{
  margin: 0;
  padding: 0;
  text-align: left;
}
.main{
  margin: 0 auto 0;
  width: 1120px;
  padding: 70px 0 0;
}
.chat_body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.chat-left {
  width: 260px;
  background-color: #fff;
}
.chat-card {
  width: 840px;
  // background-color: #fff;
  // padding-left: 10px;
  display: flex;
  flex-flow: column;
  /deep/.el-scrollbar__wrap{
    overflow-x: hidden;
  }
  .chat-top,.content-main,.chat-bottom{
    display: flex;
    align-items: flex-start;
  }
  .chat-top,.content-main,.chat-bottom{
    display: flex;
    align-items: flex-start;
  }
  .picture{
    width: 36px;
    display: flex;
    flex-flow: column;
    align-items: center;
    flex-shrink: 0;
    margin-top: 5px;
    margin-right: 10px;
    border-radius: 5px;
    img{
      width: 28px;
    }
    p{
      width: 36px;
      font-size: 12px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .chat-top{
    height: 252px;
    margin-bottom: 20px;
    .chat-body{
      width: 780px;
      height: 252px;
      display: flex;
      flex-flow: column;
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      padding: 15px;
      .name{
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0px;
        line-height: 26.06px;
        color: rgba(211, 44, 44, 1);
        text-align: left;
        vertical-align: top;
        margin-bottom: 11px;
      }
      .dec{
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 17.38px;
        color: rgba(78, 78, 78, 1);
        line-height: 22px;
        span{
          color: #ee2e2e;
        }
      }
      .questionlist{
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
        div{
          padding: 0 0 0 15px;
          line-height: 40px;
          width: 360px;
          height: 40px;
          opacity: 1;
          border-radius: 7px;
          background: rgba(254, 239, 239, 1);
          margin-top: 10px;
          color: #000;
          cursor: pointer;
        }
      }
    }
  }
  .chat-content{
    padding-top: 15px;
    padding-bottom: 50px;
    flex: 1;
    .content-body{
      width: 780px;
      .message {
        margin-bottom: 20px;
        padding: 10px;
        border-radius: 5px;
        font-size: 14px !important;
      }
      .incoming {
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 11px  rgba(0, 0, 0, 0.17);
        .copy{
          display: flex;
          justify-content: space-between;
          text-align: right;
          margin-right: 4px;
          border-top: 1px solid #dfdfdf;
          margin-top: 10px;
          padding-top: 8px;
          .see_gf{
            cursor: pointer;
          }
          .btn{
            padding-right: 8px;
            display: inline-block;
            display: flex;
            align-items: center;
            img{
              width: 18px;
              display: block;
              cursor: pointer;
            }
            span{
              color: #999;
              font-size: 12px;
            }
            .sline{
              margin: 0 10px;
              color: #999;
              display: inline-block;
              height: 20px;
              border-right: 1px solid rgba(205, 205, 205, 1);
            }
            .zan1 {
              display: block;
              width: 18px;
            }
            .zan2 {
              display: block;
              width: 18px;
            }
          }
        }
        /deep/.gh_hide_href {
          display: none !important;
        }
      }
      .outgoing {
        border-radius: 5px;
        background: rgba(241, 241, 241, 1);
        box-shadow: 0px 0px 11px  rgba(0, 0, 0, 0.17);
      }
      .unlike_box {
        padding: 10px;
        border-radius: 5px;
        background-color: #d9d9d9;
        .tags_box {
          .tag_item {
            cursor: pointer;
            display: inline-block;
            margin-right: 15px;
            width: 90px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            border-radius: 8px;
            border: 1px solid #aaa;
          }
          .active_tag {
            color: #ee2e2e;
            border: 1px solid #ee2e2e;
          }
        }
        .input_box {
          margin-top: 10px;
          display: flex;
          align-items: center;
          .submit_btn {
            cursor: pointer;
            margin-left: 10px;
            display: inline-block;
            width: 50px;
            height: 28px;
            line-height: 28px;
            border-radius: 5px;
            text-align: center;
            background-color: #ee2e2e;
            color: #fff;
          }
        }
      }
    }
  }
}

.chat-bottom{
  padding-top: 10px;
  height: 120px;
  margin-left: 46px;
  .question-body{
    width: 780px;
    height: 120px;
    // margin-top: 10px;
    opacity: 1;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /deep/.el-textarea__inner {
      resize: none; /* 禁止用户调整文本域的大小 */
      border: none;
    }
    .send{
      width: 50px;
      height: 30px;
      border-radius: 13px;
      background: #ee2e2e;
      display: flex;
      align-items: center;
      justify-content: center;
      float: right;
      cursor: pointer;
      margin: 5px 20px 0 0;
      img{
        width: 18px;
        display: block;
      }
    }
    .stop_send{
      width: 50px;
      height: 30px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      float: right;
      cursor: pointer;
      margin: 5px 20px 0 0;
      img{
        width: 30px;
        display: block;
      }
    }
  }
}

/deep/.content-body * {
  font-size: 14px !important;
  line-height: 30px !important;
}
/deep/.content-body .incoming a {
  color: #999 !important;
}
/deep/.content-body .incoming a:hover {
  color: #ee2e2e !important;
}
/deep/.content-body .incoming a:focus {
  color: #ee2e2e !important;
}
.top_add_box {
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .el-button {
    padding: 5px 20px;
    line-height: 20px;
    border-radius: 50px;
  }
  .add_i {
    margin-right: 5px;
  }
  border-bottom: 1px solid #eee;
}
.chat_list {
  padding: 0px 0px;
  overflow-y: scroll;
  height: calc(100% - 50px);
  .active_chat {
    background-color: #fff4f4;
  }
  .chat_item {
    border-bottom: 1px solid #eee;
    box-sizing: border-box;
    padding: 0px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .chat_namt {
      padding: 10px 0px;
      width: calc(100% - 25px);
      line-height: 35px;
      font-size: 14px;
      color: #333;
      word-break: break-all;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .chat_namt_red {
      color: #ee2e2e;
    }
    .ctr_span {
      display: none;
      width: 20px;
      color: #333;
      margin-left: 5px;
      font-size: 18px;
      cursor: pointer;
      /deep/.el-button--text {
        color: #333 !important;
      }
    }
    .ctr_span_block {
      display: block;
      /deep/.el-button--text {
        color: #ee2e2e !important;
      }
    }
    .input_box {
      padding: 10px 0px;
      width: calc(100% - 25px);
      display: flex;
      align-items: center;
      .close_btn {
        color: #ee2e2e;
        margin: 0 5px;
        font-size: 18px;
      }
      .done_btn {
        color: #ee2e2e;
        margin: 0 5px;
        font-size: 18px;
      }
    }
  }
  .chat_item:hover {
    background-color: #fff4f4;
    .chat_namt {
      color: #ee2e2e;
    }
   .ctr_span {
      display: block;
      /deep/.el-button--text {
        color: #ee2e2e !important;
      }
    }
  }
}
// .chat_list .chat_item:last-child{
//   border: none;
// }

// 加载动画
.dots-container {
  display: flex;
  align-items: center;
  height: 21px;
  width: 100%;
}

.dot {
  height: 5px;
  width: 5px;
  margin-right: 5px;
  border-radius: 5px;
  background-color: #dfdfdf;
  animation: pulse 1.5s infinite ease-in-out;
}

.dot:last-child {
  margin-right: 0;
}

.dot:nth-child(1) {
  animation-delay: -0.3s;
}

.dot:nth-child(2) {
  animation-delay: -0.1s;
}

.dot:nth-child(3) {
  animation-delay: 0.1s;
}

@keyframes pulse {
  0% {
    // transform: scale(0.8);
    background-color: #dfdfdf;
    box-shadow: 0 0 0 0 rgba(233, 234, 235, 0.7);
  }

  50% {
    // transform: scale(1.2);
    background-color: #333;
    box-shadow: 0 0 0 2px rgba(178, 212, 252, 0);
  }

  100% {
    // transform: scale(0.8);
    background-color: #dfdfdf;
    box-shadow: 0 0 0 0 rgba(233, 234, 235, 0.7);
  }
}
.setImg{
  word-break: break-all;
  /deep/img{
    width: 100%;
  }
}
.menu_pop {
  .pop_menus {
    .p1 {
      padding-left: 20px;
      line-height: 40px;
      color: #333;
      border-bottom: 1px solid #eee;
      i {
        margin-right: 10px;
      }
    }
    .p2 {
      padding-left: 20px;
      line-height: 40px;
      color: #ee2e2e;
      i {
        margin-right: 10px;
      }
    }

  }
}
</style>
